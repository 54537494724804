import flow from 'lodash/flow';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
  selectIsGdprReady,
  selectIsMobile,
  selectUserAgent,
} from 'src/common/selectors/app';
import { selectIsSubscriptionEnabled } from 'src/common/selectors/config';
import {
  WEB_FIRST_PLAY_INSTALL_APP_DIALOG,
  WEB_OPEN_INSTALL_APP_DIALOG,
  WEB_OPEN_INSTALL_APP_DIALOG_DISABLED_GUIDEIDS,
} from '../../constants/experiments/dialog';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import isContentPage from '../../utils/subscription/isContentPage';
import VideoAdDialog from '../ads/VideoAdDialog';
import DownloadAppDialog from './DownloadAppDialog';
import ErrorDialog from './ErrorDialog';
import InAppMessageDialog from './InAppMessageDialog';
import InterestSelectorDialog from './InterestSelectorDialog';
import LinkWithAlexaDialog from './LinkWithAlexaDialog';
import NavBasedDialogManager from './NavBasedDialogManager';
import NowPlayingDialog from './NowPlayingDialog';
import PopoutPlayerDialog from './PopoutPlayerDialog';
import ShareDialogContainer from './ShareDialogContainer';
import TextMeTheAppDialog from './TextMeTheAppDialog';

export class GlobalDialogs extends Component {
  static propTypes = {
    breakpoint: PropTypes.number.isRequired,
    routeProps: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    showFloatingGdprButton: PropTypes.bool.isRequired,
    // mapStateToProps
    isSubscriptionEnabled: PropTypes.bool.isRequired,
    userAgent: PropTypes.string,
    gdprObligationsComplete: PropTypes.bool.isRequired,
    // connectWithExperiments
    [WEB_OPEN_INSTALL_APP_DIALOG]: PropTypes.bool,
    [WEB_OPEN_INSTALL_APP_DIALOG_DISABLED_GUIDEIDS]: PropTypes.string,
    [WEB_FIRST_PLAY_INSTALL_APP_DIALOG]: PropTypes.bool,
    canShowAds: PropTypes.bool.isRequired,
    sponsorshipEnabled: PropTypes.bool.isRequired,
  };

  render() {
    const {
      // Parent
      breakpoint,
      routeProps,
      history,
      showFloatingGdprButton,
      // map state to props
      isSubscriptionEnabled,
      userAgent,
      gdprObligationsComplete,
      // connectWithExperiments
      [WEB_OPEN_INSTALL_APP_DIALOG]: openInstallDialogExperiment,
      [WEB_OPEN_INSTALL_APP_DIALOG_DISABLED_GUIDEIDS]:
        hideInstallDialogGuideIdsExperiment,
      [WEB_FIRST_PLAY_INSTALL_APP_DIALOG]: firstPlayInstallDialogExperiment,
      canShowAds,
      sponsorshipEnabled,
    } = this.props;

    const hideInstallDialogGuideIds = hideInstallDialogGuideIdsExperiment
      ? hideInstallDialogGuideIdsExperiment.split(',')
      : '';

    if (routeProps.showErrorDialogOnly) {
      return <ErrorDialog />;
    }

    if (routeProps.showNowPlayingDialogOnly) {
      return (
        <NowPlayingDialog
          breakpoint={breakpoint}
          routeProps={routeProps}
          canShowAds={canShowAds}
          sponsorshipEnabled={sponsorshipEnabled}
        />
      );
    }

    if (routeProps.showVideoAdDialogOnly) {
      return <VideoAdDialog breakpoint={breakpoint} />;
    }

    if (routeProps.showNowPlayingAndVideoAdDialogsOnly) {
      return (
        <>
          <NowPlayingDialog
            breakpoint={breakpoint}
            routeProps={routeProps}
            canShowAds={canShowAds}
            sponsorshipEnabled={sponsorshipEnabled}
          />
          <VideoAdDialog breakpoint={breakpoint} />
        </>
      );
    }

    if (routeProps.showAuthAndAdDialogsOnly) {
      return (
        <>
          {!routeProps.hideAds && <VideoAdDialog breakpoint={breakpoint} />}
          <NavBasedDialogManager
            isSubscriptionEnabled={isSubscriptionEnabled}
            routeProps={routeProps}
            history={history}
            key={`NavBasedDialogManager-${routeProps.matchUrl}`}
            showAuthAndAdDialogsOnly
          />
        </>
      );
    }

    return (
      <>
        {!routeProps.hideAds && <VideoAdDialog breakpoint={breakpoint} />}
        <NavBasedDialogManager
          isSubscriptionEnabled={isSubscriptionEnabled}
          routeProps={routeProps}
          history={history}
          key={`NavBasedDialogManager-${routeProps.matchUrl}`}
        />
        <InterestSelectorDialog />
        <LinkWithAlexaDialog />
        <InAppMessageDialog />
        <NowPlayingDialog
          breakpoint={breakpoint}
          routeProps={routeProps}
          canShowAds={canShowAds}
          sponsorshipEnabled={sponsorshipEnabled}
        />
        {(isContentPage(routeProps) || routeProps.isLanding) &&
          gdprObligationsComplete && (
            <TextMeTheAppDialog
              routeProps={routeProps}
              userAgent={userAgent}
              showFloatingGdprButton={showFloatingGdprButton}
            />
          )}
        {(openInstallDialogExperiment || firstPlayInstallDialogExperiment) &&
          !includes(
            hideInstallDialogGuideIds,
            routeProps?.guideContext?.guideId,
          ) && <DownloadAppDialog />}
        <PopoutPlayerDialog />
        <ErrorDialog />
        <ShareDialogContainer />
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  isMobile: selectIsMobile(state),
  userAgent: selectUserAgent(state),
  isSubscriptionEnabled: selectIsSubscriptionEnabled(state),
  gdprObligationsComplete: selectIsGdprReady(state),
});

export default flow(
  connectWithExperiments([
    WEB_OPEN_INSTALL_APP_DIALOG,
    WEB_OPEN_INSTALL_APP_DIALOG_DISABLED_GUIDEIDS,
    WEB_FIRST_PLAY_INSTALL_APP_DIALOG,
  ]),
  connect(mapStateToProps),
)(GlobalDialogs);
