import camelCase from 'lodash/camelCase';
import { Vibrant } from 'node-vibrant/browser';

export async function getPalette(src) {
  const palette = await Vibrant.from(src).getPalette();
  const setPaletteColor = (acc, paletteName) => ({
    ...acc,
    [camelCase(paletteName)]: palette[paletteName]?.hex,
  });

  return Object.keys(palette).reduce(setPaletteColor, {});
}
